<template>
  <div>
    <div class="d-md-flex mb-3">
      <div :class="isMobile ? 'w-100' : 'mr-3'">
        <a-select
          :class="isMobile ? 'w-100' : null"
          size="large"
          placeholder="Select Year"
          v-model="selectedYear"
          @change="changeSelectedYear"
        >
          <a-select-option
            v-for="item in listTahunAjaran"
            :key="item.id"
            :value="item.id"
            >{{ `${item.tahun}` }}
          </a-select-option>
        </a-select>
        <!-- <a-dropdown>
          <a-menu slot="overlay" @click="refreshTable">
            <a-menu-item key="1">
              Upload New
            </a-menu-item>
            <a-menu-item key="2">
              Download Template
            </a-menu-item>
          </a-menu>
          <a-button size="large" type="primary"> Actions <a-icon type="down" /> </a-button>
        </a-dropdown> -->
        <a href="/Template SKL.xlsx">
          <a-button
            :class="isMobile ? ['w-100', 'my-3'] : ['mx-3']"
            size="large"
          >
            <a-icon type="download" />
            Download Template
          </a-button>
        </a>
        <!-- <a-button
          type="primary"
          :class="isMobile ? ['w-100', 'my-3'] : ['mr-3']"
          size="large"
          @click.prevent="refreshTable"
        >
          <a-icon type="download" />
          Download Template
        </a-button>
        <a-button
          type="secondary"
          :class="isMobile ? ['w-100', 'my-3'] : ['mr-3']"
          size="large"
          @click.prevent="refreshTable"
        >
          <a-icon type="upload" />
          Upload New
        </a-button> -->
      </div>
      <div class="ml-auto">
        <a-upload
          :fileList="[]"
          name="graduation-letter"
          id="graduation-letter-uploader"
          action=""
          :beforeUpload="file => uploadFile(file)"
          :customRequest="() => {}"
        >
          <a-button
            :class="isMobile ? ['w-100', 'my-3'] : null"
            size="large"
            type="primary"
          >
            <a-icon type="upload" />
            Generate Graduation Letter
          </a-button>
        </a-upload>
      </div>
    </div>
    <a-table
      class="hasan-table-overflow"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loadingTable"
      bordered
    >
    <div slot="walikelas" slot-scope="text">
      <div v-if="text">{{text}}</div>
      <div v-else>-</div>
    </div>
    <div slot="action" slot-scope="value, record">
      <a-button @click.prevent="toGraduationLetterDetail(record.key)" class="text-primary border-primary" icon="book">Graduation Letter Details</a-button>
    </div>
  </a-table>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

const columns = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 150,
  },
  {
    title: 'Homeroom Teacher',
    dataIndex: 'walikelas',
    key: 'walikelas',
    scopedSlots: { customRender: 'walikelas' },
  },
  // {
  //   title: 'Progress',
  //   children: [
  //     {
  //       title: 'Spiritual',
  //       dataIndex: 'spiritual',
  //       key: 'spiritual',
  //       align: 'center',
  //       width: 200,
  //       scopedSlots: { customRender: 'percentage' },
  //     },
  //     {
  //       title: 'Social',
  //       dataIndex: 'social',
  //       key: 'social',
  //       align: 'center',
  //       width: 200,
  //       scopedSlots: { customRender: 'percentage' },
  //     },
  //   ],
  // },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  data() {
    return {
      loadingTable: false,
      loadingRefresh: false,
      type: 'mid',
      columns,
      data: [],
      idKelas: this.$route.params.id,
      selectedYear: null,
      listTahunAjaran: [],
      activeYear: {},
    }
  },
  methods: {
    toGraduationLetterDetail(idKelas) {
      this.$router.push({ name: 'Graduation Letter Homeroom Teacher', params: { id_kelas: idKelas } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Currriculum Teacher'],
      })
    },
    handleChangeType() {
      this.fetchDataTable()
    },
    changeSelectedYear() {
      this.fetchDataTable()
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchDataTable()
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const res = await this.$store.dispatch('curriculum/FETCH_GRADUATION_LETTER_BY_LEVEL_AND_ID_TAHUN_AJARAN', { idTahunAjaran: this.selectedYear })
        this.loadingRefresh = false
        this.loadingTable = false
        this.data = (res.data || []).map(dataClass => {
          return {
            ...dataClass,
            key: dataClass.id,
          }
        })
        // console.log(res)
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataTahunAjaran() {
      try {
        const res = await this.$store.dispatch('curriculum/FETCH_TAHUN_AJARAN')
        this.activeYear = res.activeYear
        this.listTahunAjaran = res.data.filter(dat => dat.semester === 'Genap')
        this.selectedYear = res.activeYear.id
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async uploadFile(file) {
      try {
        const isExcel = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)
        if (!isExcel) {
          this.$notification.error({
            message: 'File not supported',
            description: 'Please upload only excel (.xls, .xlsx) file to continue',
          })

          return false
        }

        const year = this.listTahunAjaran.find(dat => dat.id === this.selectedYear)?.tahun

        this.$confirm({
          title: `Are you sure to generate new graduation letter for ${year}?`,
          content: `When proceed, it will overwrite current graduation letter on year ${year}`,
          onOk: async () => {
            try {
              const formData = new FormData()
              formData.append('skl', file)
              await axios.post(`${config.apiUrl}/api/skl/upload`, formData, {
                headers: {
                  token: localStorage.token,
                  'Access-Control-Allow-Origin': '*',
                },
              })
              this.$notification.success({
                message: 'Graduation Letter Successfully Generated',
                description: `Graduation Letter on ${year} successfully generated.`,
              })
              return new Promise((resolve, reject) => {
                resolve()
              })
            } catch (err) {
              this.$notification.error({
                message: 'INTERNAL SERVER ERROR',
                description: 'There is an error during generating graduation letter',
              })
              console.log(err)
            }
          },
          onCancel() {
            console.log('cancel')
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  async mounted() {
    await this.fetchDataTahunAjaran()
    this.fetchDataTable()
  },
}
</script>

<style>

</style>
